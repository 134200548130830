import { Injectable, OnInit, Inject } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AdminSettings } from '../../../shared/models/admin-settings';
import { Observable, of, throwError, BehaviorSubject } from 'rxjs';
import { concatMap, delay, map, publishReplay, refCount, retryWhen, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { MenuService, MenuElement } from '../../../layout/sidebar/menu.service';
import { ProfileService } from '../profile/profile.service';

@Injectable()
export class SettingsService implements OnInit {
    public styles: SafeHtml;
    public observableSettings: BehaviorSubject<AdminSettings>;
    public settingsObserver: Observable<any>;
    private _settings;
    private fetchedSettings = false;
    private fetchingSettings = false;
    activeModule: 'company' | 'employee' | null = null;
    private moduleStyles = new Map<string, SafeHtml>();

    get settings() {
        return this._settings;
    }

    set settings(opt) {
        this._settings = new AdminSettings(opt);
    }

    constructor(
        @Inject('MODULE') private module: string,
        private http: HttpClient,
        private domSanitizer: DomSanitizer,
        private menuService: MenuService,
        private profileService: ProfileService
    ) {
        const DEFAULT_SETTINGS: AdminSettings = new AdminSettings({
            color: '#000',
            date_format: 'F j, Y',
            domain: '',
            email: '',
            logo: '',
            time_format: 'g:i a',
            timezone: 'Europe/London',
            week_starts_on: 'Monday',
            texts: [],
        });

        this.settings = new AdminSettings(DEFAULT_SETTINGS);
        this.observableSettings = new BehaviorSubject<AdminSettings>(
            this.settings
        );
    }

    /* tslint:disable:no-bitwise rule2 rule3... */
    private static lightenDarkenColor(col: string, amt: number): string {
        let color = col;
        if (!col) {
            color = '#000';
        }
        let usePound = false;
        if (color[0] === '#') {
            color = color.slice(1);
            usePound = true;
        }

        const num = parseInt(color, 16);

        let r = (num >> 16) + amt;

        if (r > 255) {
            r = 255;
        } else if (r < 0) {
            r = 0;
        }

        let b = ((num >> 8) & 0x00ff) + amt;

        if (b > 255) {
            b = 255;
        } else if (b < 0) {
            b = 0;
        }

        let g = (num & 0x0000ff) + amt;

        if (g > 255) {
            g = 255;
        } else if (g < 0) {
            g = 0;
        }

        return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
    }

    private static hexToRgbA(hex: string, alpha: number = 1): string {
        let c;
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            c = hex.substring(1).split('');
            if (c.length === 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = '0x' + c.join('');
            return (
                'rgba(' +
                [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') +
                ',' +
                alpha +
                ')'
            );
        }
        throw new Error('Bad Hex');
    }

    private static createStyles(options: AdminSettings): string {
        const color = options.color;
        if (!options.color || options.color === 'null') {
            console.error('No color on settings');
            return '';
        }
        /* tslint:disable:no-trailing-whitespace */
        return `<style>
            a {
                color: ${color};
            }

            a.light:hover {
                color: ${color};
            }

            a:hover,
            .link-default:hover {
                color: ${SettingsService.lightenDarkenColor(color, -10)};
            }

            .text-primary,
            .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
                color: ${color} !important;
            }

            .bg-primary {
                background-color: ${color} !important;
            }

            a.bg-primary:hover {
                background-color: ${SettingsService.lightenDarkenColor(
                    color,
                    -20
                )} !important;
            }

            .color-primary {
                color: ${color} !important;
            }

            .bg-primary-dark {
                background-color: ${SettingsService.lightenDarkenColor(
                    color,
                    -10
                )} !important;
            }

            .btn-primary:hover {
                background-color: ${SettingsService.lightenDarkenColor(
                    color,
                    -20
                )};
                border-color: ${SettingsService.lightenDarkenColor(color, 10)};
            }

            form .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
                border: 4px solid ${color} !important;
                border-color: ${color} !important;
            }

            .btn-primary,
            .btn-primary.disabled,
            .btn-primary:disabled,
            .mat-sidenav-container .btn-primary,
            .modal .btn-primary,
            .layout-unauthorized .btn-primary,
            .mat-dialog-container .btn-primary,
            .pager > li.pages.active,
            .mat-tab-header .mat-tab-labels .mat-tab-label.mat-tab-label-active {
                background-color: ${color} !important;
                border-color: ${color} !important;
            }

            .mat-sidenav-container .btn-primary:hover{
                background-color: ${SettingsService.lightenDarkenColor(
                    color,
                    -20
                )} !important;
                border-color: ${SettingsService.lightenDarkenColor(color, -10)};
            }

            .btn-primary:not(:disabled):not(.disabled):active,
            .btn-primary:not(:disabled):not(.disabled).active,
            .show > .btn-primary.dropdown-toggle {
                background-color: ${SettingsService.lightenDarkenColor(
                    color,
                    -10
                )};
                border-color: ${SettingsService.lightenDarkenColor(color, -10)};
            }

            .btn-primary:hover,
            .btn-primary:focus,
            .mat-sidenav-container .btn-primary:hover,
            .mat-sidenav-container .btn-primary:focus,
            .modal .btn-primary:hover,
            .modal .btn-primary:focus,
            .layout-unauthorized .btn-primary:hover,
            .layout-unauthorized .btn-primary:focus,
            .mat-dialog-container .btn-primary:hover,
            .mat-dialog-container .btn-primary:focus,
            .layout-unauthorized .btn-primary:hover,
            {
                background: ${SettingsService.lightenDarkenColor(
                    color,
                    -20
                )} !important;
                border: 1px solid ${color} !important;
                box-shadow: none !important;
            }

            .btn.btn-secondary:not(:disabled):active:focus,
            .btn.btn-secondary:not(:disabled):active,
            .btn.btn-secondary:not(:disabled):focus,
            .btn.btn-secondary:not(:disabled):hover,
            .btn.btn-default:not(:disabled):active:focus,
            .btn.btn-default:not(:disabled):active,
            .btn.btn-default:not(:disabled):focus,
            .btn.btn-default:not(:disabled):hover {
                color: ${color} !important;
            }

            .btn.btn-secondary:not(:disabled):not(.disabled):active,
            .btn.btn-secondary:not(:disabled):not(.disabled).active,
            .show > .btn.btn-secondary.dropdown-toggle,
            .btn.btn-default:not(:disabled):not(.disabled):active,
            .btn.btn-default:not(:disabled):not(.disabled).active,
            .show > .btn.btn-default.dropdown-toggle {
                background-color: ${SettingsService.lightenDarkenColor(
                    color,
                    70
                )};
                border-color: ${SettingsService.lightenDarkenColor(color, 70)};
            }

            .mat-tab-header .mat-tab-labels .mat-tab-label.mat-tab-label-active:hover,
            .mat-tab-label.mat-tab-label-active:hover {
                background-color: ${color} !important;
                color: #FFFFFF;
                border: 1px solid ${color} !important;
            }

            .mat-accent .mat-slider-track-fill, .mat-accent .mat-slider-thumb, .mat-accent .mat-slider-thumb-label {
                background-color: ${color} !important;
            }



            .mat-radio-button.mat-accent .mat-radio-inner-circle {
                background-color: ${color} !important;
            }


            .mat-radio-button.mat-accent.mat-radio-checked  .mat-radio-outer-circle {
                border-color: ${color} !important;
            }

            .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-inner-circle {
                background-color: ${color} !important;
            }

            .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
                background-color: ${color} !important;
            }

            .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
                background-color: ${color} !important;
            }

            .mat-checkbox.mat-accent .mat-checkbox-ripple .mat-ripple-element {
                background-color: ${color} !important;
            }


            .ng-option.marked {
                color: ${color} !important;
            }

            .mat-tab-group.mat-primary .mat-ink-bar,
            .mat-tab-nav-bar.mat-primary .mat-ink-bar {
                background-color: ${color} !important;
            }

            .mat-slide-toggle.mat-accent.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
                background-color: ${color} !important;
            }

            .mat-slide-toggle .mat-ripple-element {
                background-color: ${SettingsService.hexToRgbA(color, 0.12)};
            }

            .ngx-datatable.mrc-datatable .progress-linear .container {
                background-color: ${SettingsService.hexToRgbA(color, 0.3)};
            }

            .ngx-datatable.mrc-datatable .progress-linear .container .bar, .mat-progress-bar-fill::after {
                background-color: ${color} !important;
            }

             .mat-progress-bar-background, .mat-progress-bar-buffer {
                fill: ${SettingsService.hexToRgbA(color, 0.3)} !important;
                background-color: ${SettingsService.hexToRgbA(
                    color,
                    0.3
                )} !important;
            }

            .sidebar-menu-block-item a:hover,
            .sidebar-menu-block-item a.active {
                color: ${color} !important;
            }

            .ng-star-inserted .select-start:before,
            .ng-star-inserted .in-range:before,
            .ng-star-inserted .select-end:before {
                background-color: ${color} !important;
            }

            .ng-star-inserted .is-highlighted:before {
                background-color: ${SettingsService.hexToRgbA(
                    color,
                    0.3
                )} !important;
            }

            .ng-star-inserted .is-highlighted {
                color: ${color} !important;
            }

            .ng-star-inserted .select-start:after,
            .ng-star-inserted .select-end:after {
                background-color: ${color} !important;
            }

            .ng-star-inserted .is-highlighted:after {
                background-color: transparent !important;
            }

            .years .is-highlighted {
                border-color: ${color} !important;
            }
            .mat-progress-spinner circle,
            .mat-spinner circle {
                stroke: ${color} !important;
            }


            input[type=radio]:checked + .chart,
            input[type=radio]:checked + .chart .chart-icon {
                border-color: ${color} !important;
                color: ${color} !important;
            }

            input[type=radio]:checked ~ .chart-label {
                color: ${color} !important;
            }

            .label-source .source-radio:before {
                background: ${color} !important;
            }

            .dropdown-item:active,
            .dropdown-item:hover {
                color: ${color} !important;
                background: transparent !important;
            }

            .card-icon:hover, .preloader i, .label-source:hover,
            .card-icon.active, .flat-rate-message {
                color: ${color} !important;
            }

            .datatable-body-row:hover, tr:hover, .sidebar-menu-block-item a.active:before {
                border-left-color: ${color} !important;
            }

            .border-selected {
                border-color: ${SettingsService.lightenDarkenColor(color, 10)} !important;
            }

            .sidebar-menu-block-item a.call-to-action.bg-primary {
                background-color: ${color} !important;
            }

            .sidebar-menu-block-item a.call-to-action.bg-primary:hover {
                background-color: ${SettingsService.lightenDarkenColor(color, -20)} !important;
                border: 1px solid ${SettingsService.lightenDarkenColor(color, -20)} !important;
            }
        </style>`;
        /* tslint:enable:no-trailing-whitespace */
    }

    ngOnInit() {
        if (location.href.indexOf(`admin`) !== -1) {
            this.getSettings().subscribe();
        }

        this.settingsObserver.subscribe((value) => {
            this.settings = value;
        });
    }

    updateSettings(data) {
        this.settings = { ...this.settings, ...data };
        this.observableSettings.next(this.settings);
        this.updateStyles();
    }

    public updateStyles() {
        const styles = this.parseStyles();
        if (this.activeModule) {
            this.moduleStyles.set(this.activeModule, styles);
        } else {
            this.styles = styles;
        }
    }

    getActiveModule(): 'company' | 'employee' | null {
        return this.activeModule;
    }

    setActiveModule(module: 'company' | 'employee'): void {
        this.activeModule = module;
        this.updateStyles();
    }

    public parseStyles() {
        const styles = SettingsService.createStyles(this.settings);
        return this.domSanitizer.bypassSecurityTrustHtml(styles);
    }

    public getStyles(): SafeHtml {
        if (this.activeModule === 'employee') {
            return this.moduleStyles.get('employee') || this.parseStyles();
        }
        return this.styles || this.parseStyles();
    }

    public getFreshSettings(): Observable<any> {
        return this.http.get<any>(`settings`).pipe(
                map(({ payload }) => payload),
            );
    }

    public getExtraCompanySettings(companyId: number | string): Observable<any> {
        return this.http.get<any>(`settings/extra-company-settings/${companyId}`);
    }

    public getSettings(): Observable<any> {
        if (
            (!this.fetchedSettings && !this.fetchingSettings) ||
            !this.settingsObserver
        ) {
            this.settingsObserver = this.http.get<any>(`settings`).pipe(
                map(({ payload }) => payload),
                tap((settings) => {
                    this.updateSettings(new AdminSettings(settings));
                    this.profileService.getProfile().subscribe((profile) => {
                        const menuArray = this.menuService.menuSubject.getValue() as MenuElement;
                        if (
                            !menuArray ||
                            !menuArray.menu ||
                            menuArray.menu.length === 0
                        ) {
                            return;
                        }
                        if (
                            this.module !== 'system' &&
                            settings.inspectionEnabled !== null &&
                            !settings.inspectionEnabled
                        ) {
                            let baseIndex = 0;
                            let inspectionMenuIndex = -1;
                            menuArray.menu.forEach((block, index) => {
                                if (block.links && inspectionMenuIndex < 0) {
                                    baseIndex = index;
                                    inspectionMenuIndex = block.links.findIndex(
                                        (t) => t && t.name === 'Inspektion'
                                    );
                                }
                            });
                            if (inspectionMenuIndex >= 0) {
                                if (this.module === 'supplier') {
                                    delete menuArray.menu[baseIndex];
                                } else {
                                    delete menuArray.menu[baseIndex].links[
                                        inspectionMenuIndex
                                    ];
                                }
                            }
                            this.menuService.menuSubject.next(menuArray);
                        }
                    });
                    this.fetchedSettings = true;
                    this.fetchingSettings = false;
                }),
                publishReplay(1),
                refCount(),
                retryWhen((errors) =>
                    errors.pipe(
                        concatMap((error, count) => {
                            if (count < 5) {
                                return of(error);
                            }
                            this.clearCache();
                            return throwError(error);
                        }),
                        delay(500)
                    )
                )
            );
        }
        return this.settingsObserver;
    }

    public clearCache() {
        this.settingsObserver = null;
        this.fetchedSettings = false;
        this.fetchingSettings = false;
    }
}
